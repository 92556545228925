import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/Layout';

export const FooterPageTemplate = ({
  tasaInteres
}) => {

  return (
    <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
      {tasaInteres}
    </h2>
  );
};

FooterPageTemplate.propTypes = {
  tasaInteres: PropTypes.string.isRequired,
};

const FooterPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FooterPageTemplate
        tasaInteres={post.frontmatter.tasaInteres}
      />
    </Layout>
  );
};

FooterPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FooterPage;

export const footerPageQuery = graphql`
  query footerQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
        frontmatter {
        tasaInteres
        tasaMaxima
        }
    }
}
`;
